import React from 'react'
import Button from '../Button/Button'
import { Marker, Popup, } from 'react-leaflet'
import styles from './MapMarker.module.scss'
import { Checkbox, ListItem, ListItemIcon, } from '@material-ui/core'

export default function MapMarker({ panel, checked, handleToggle }) {

function parseCoordinates(input) {
  try {
    // Trim whitespace and split the string by comma
    const parts = input
      .trim()
      .split(",")
      .map((part) => part.trim());

    const latitude = parseFloat(parts[0]);
    const longitude = parseFloat(parts[1]);

    if (!isNaN(latitude) && !isNaN(longitude)) return [latitude, longitude];
  } catch (error) {
    console.error("Error parsing coordinates: ", error);
  }
  return [0, 0];
}

  const position = parseCoordinates(panel.location);
	const panelFormat = panel.displayFormat === "portrait" ? "Hochformat" : "Querformat";

	return (
    <Marker position={position}>
      <Popup className={styles.panel}>
        <ListItem
          key={panel._id}
          className={styles.list_item}
          role={undefined}
          button
          onClick={handleToggle(panel._id)}
        >
          <ListItemIcon>
            <Checkbox
              checked={checked.indexOf(panel._id) !== -1}
              tabIndex={-1}
              disableRipple={true}
              inputProps={{ "aria-labelledby": panel.name }}
            />
          </ListItemIcon>
          <div className={styles.panel}>
            <div className={styles.text}>
              <div className={styles.tags}>
                <p>{panel.size}'</p>
                <p>{panelFormat}</p>
              </div>
              <h5 className={styles.name}>{panel.name}</h5>
              <p className={styles.address}>{panel.address}</p>
              <h5 className={styles.price}>ab {panel.price} € pro Monat</h5>
            </div>
          </div>
        </ListItem>
      </Popup>
    </Marker>
  );
}
